var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-notes" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              [
                _vm._v(" : "),
                _c(
                  "h3",
                  { staticClass: "d-inline" },
                  [
                    _c("CBadge", { attrs: { color: "secondary" } }, [
                      _vm._v(_vm._s(this.itemName)),
                    ]),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: { name: "All Users" } } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c("alert-section", {
            attrs: {
              successAlertMessage: _vm.successAlertMessage,
              dismissSecs: _vm.dismissSecs,
              dismissSuccessAlert: _vm.dismissSuccessAlert,
              errorAlertMessage: _vm.errorAlertMessage,
              showErrorAlert: _vm.showErrorAlert,
            },
          }),
          _c(
            "CForm",
            [
              _c(
                "CRow",
                [
                  _c("CCol", { attrs: { lg: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group w-100" },
                      [
                        _c("label", { staticClass: "d-block" }, [
                          _vm._v("User Type:"),
                        ]),
                        _c("CInputRadioGroup", {
                          attrs: {
                            name: "usertype",
                            options: ["User", "Admin"],
                            checked: _vm.$v.form.usertype.$model,
                            custom: "",
                            inline: "",
                          },
                          on: {
                            "update:checked": function ($event) {
                              return _vm.$set(
                                _vm.$v.form.usertype,
                                "$model",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("CCol", { attrs: { lg: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group w-100" },
                      [
                        _c("label", { staticClass: "d-block" }, [
                          _vm._v("Roles:"),
                        ]),
                        _c("v-select", {
                          attrs: {
                            options: _vm.allRoles,
                            placeholder: "No role selected!",
                            label: "name",
                            disabled: _vm.form.usertype === "User",
                            searchable: false,
                            multiple: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (roles) {
                                return [
                                  _c("span", [_vm._v(_vm._s(roles.name))]),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.$v.form.roles.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.roles, "$model", $$v)
                            },
                            expression: "$v.form.roles.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "CCol",
                    { attrs: { lg: "6" } },
                    [
                      _c("CInput", {
                        staticClass: "w-100",
                        attrs: {
                          label: "2FA Mobile Number:",
                          lazy: false,
                          value: _vm.$v.form.tfamobile.$model,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.tfamobile,
                              "$model",
                              $event
                            )
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-content",
                            fn: function () {
                              return [
                                _c("CIcon", { attrs: { name: "cil-mobile" } }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardFooter",
        [
          _c(
            "CButton",
            {
              attrs: { color: "primary", disabled: _vm.submitted },
              on: { click: _vm.update },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }